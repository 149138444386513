import React, { useState } from 'react';
import { LogoutBar } from '../components/LogoutBar'; // Import the LogoutBar component
import SupportModal from '../components/SupportModal'; // Import the SupportModal component

const Header: React.FC = () => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState<boolean>(false);

  return (
    <>
      <header className="w-full bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white shadow-2xl">
        <div className="max-w-6xl mx-auto px-8 py-5 flex items-center justify-between">
          {/* Left Section - Logo and Title */}
          <div className="flex items-center space-x-6">
            <img
              src="/testlogo.png"
              alt="Logo"
              className="w-14 h-14 object-contain hover:opacity-90 transition-opacity duration-300 transform hover:scale-110"
            />
            <h1 className="text-3xl font-extrabold tracking-tight hover:text-teal-400 transition-colors duration-300">
              Skjáborð
            </h1>
          </div>

          {/* Right Section - Logout and Additional Links */}
          <div className="flex items-center space-x-10">
            <button
              onClick={() => setIsSupportModalOpen(true)}
              className="text-teal-400 hover:text-teal-200 transition-colors duration-300 focus:outline-none text-xl flex items-center space-x-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5v1.125a3.375 3.375 0 11-6.75 0V10.5M5.25 10.5a7.5 7.5 0 1115 0v1.125a6 6 0 01-12 0V10.5" />
              </svg>
              <span>Aðstoð</span>
            </button>
            <a href="#" className="text-teal-400 hover:text-teal-200 transition-colors duration-300 text-xl">
              Stillingar
            </a>
            <LogoutBar />
          </div>
        </div>

        {/* Animated Border */}
        <div className="w-full h-1 bg-gradient-to-r from-teal-500 via-purple-500 to-blue-500 animate-pulse"></div>
      </header>

      {/* Support Modal */}
      <SupportModal isOpen={isSupportModalOpen} onClose={() => setIsSupportModalOpen(false)} />
    </>
  );
};

export default Header;
