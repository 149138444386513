// src/data/infoCards.ts

export interface InfoCardData {
    bgColor: string;
    icon: string;
    title: string;
    description: string;
  }
  
  export const allInfoCards: InfoCardData[] = [
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🕒",
      title: "Ása er til staðar 24/7",
      description: "Fáðu ráðgjöf eða aðstoð hvenær sem er, allan sólarhringinn.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🚨",
      title: "Neyðartilvik",
      description: "Í neyðartilvikum hringdu í 112.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🩺",
      title: "Sjúkdómagreining",
      description: "Ása greinir sjúkdóma og vísar þér til réttra sérfræðinga.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "📅",
      title: "Bókaðu tíma",
      description: "Bókaðu tíma hjá sérfræðingum með hjálp Ásu.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "💬",
      title: "Ráðgjöf",
      description: "Fáðu ráðgjöf frá sérfræðingum í gegnum spjall.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🧘",
      title: "Heilsuráð",
      description: "Góð ráð til að bæta heilsu og líðan.",
    },
    {
      bgColor: "bg-gradient-to-r from-indigo-400 via-purple-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "💉",
      title: "Bólusetningar",
      description: "Upplýsingar um bólusetningar og hvernig má bóka tíma.",
    },
    {
      bgColor: "bg-gradient-to-r from-green-400 via-teal-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "📖",
      title: "Hefja spjall",
      description: "Ýttu á 'Hefja nýtt spjall' til að fá ráðgjöf.",
    },
    {
      bgColor: "bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🖊️",
      title: "Skrá upplýsingar",
      description: "Fylltu út upplýsingar í prófíl til að fá persónulega ráðgjöf.",
    },
    {
      bgColor: "bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-500 bg-opacity-20 backdrop-blur-xl",
      icon: "💡",
      title: "Fyrri samtöl",
      description: "Skoðaðu fyrri samtöl í hliðarstikunni.",
    },
    {
      bgColor: "bg-gradient-to-r from-pink-400 via-purple-500 to-indigo-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🎲",
      title: "Shuffle hnappurinn",
      description: "Notaðu shuffle til að skoða mismunandi upplýsingar og möguleika.",
    },
    {
      bgColor: "bg-gradient-to-r from-red-400 via-yellow-500 to-green-500 bg-opacity-20 backdrop-blur-xl",
      icon: "👨‍⚕️",
      title: "Aðstoð frá heilbrigðisstarfsfólki",
      description: "Heilbrigðisstarfsfólk getur skoðað spjöllin þín til að aðstoða betur.",
    },
    {
      bgColor: "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-opacity-20 backdrop-blur-xl",
      icon: "❓",
      title: "Spyrðu Ásu",
      description: "Spyrðu Ásu um ráðleggingar, t.d. um tíma hjá lækni.",
    },
    {
      bgColor: "bg-gradient-to-r from-teal-400 via-blue-500 to-indigo-500 bg-opacity-20 backdrop-blur-xl",
      icon: "📅",
      title: "Bóka tíma hjá lækni",
      description: "Ása getur hjálpað að finna hentugan tíma hjá lækni.",
    },
    {
      bgColor: "bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 bg-opacity-20 backdrop-blur-xl",
      icon: "🤖",
      title: "Ása - Fyrsti AI starfsmaðurinn",
      description: "Ása er fyrsti AI starfsmaður heilsugæslunnar og getur veitt hraða og nákvæma aðstoð.",
    },
  ];
  