import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { apiGetCurrentUser } from '../api/auth';
import AccountDropdown from '../components/AccountDropdown';
import { useNavigate } from 'react-router-dom';

const ProfileView: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    avatar: '',
    ssn: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await apiGetCurrentUser();
        const userData = response.data.user;
        setProfile({
          name: userData.fullname || '',
          email: userData.email || '',
          avatar: userData.photoURL || '',
          ssn: userData.ssn || ''
        });
      } catch (err) {
        setError('Failed to load profile');
      }
      setLoading(false);
    };

    if (authContext && authContext.user) {
      setProfile({
        name: authContext.user.fullname || '',
        email: authContext.user.email || '',
        avatar: authContext.user.photoURL || '',
        ssn: authContext.user.ssn || ''
      });

      fetchProfile();
    } else {
      setError('User not authenticated');
    }
  }, [authContext]);

  if (loading) return <p className="text-center text-blue-400">Loading profile...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col items-center relative">
      {/* Fixed Account Dropdown and Back to Chat Arrow */}
      <div className="absolute top-6 right-6 z-50 flex items-center space-x-4">
        {/* Back to Chat Arrow */}
        <button
          onClick={() => navigate('/chat')}
          className="p-2 rounded-full bg-gradient-to-r from-pink-400 to-purple-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-600 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        {/* Account Dropdown */}
        <AccountDropdown onLogout={() => console.log('Logged out')} />
      </div>

      {/* Full-Window Profile Section */}
      <div className="w-full max-w-5xl p-12 flex flex-col justify-center items-center space-y-12">
        <h2 className="text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-400 via-purple-500 to-blue-500 text-center leading-tight">
          Um þig
        </h2>

        {/* Profile Information */}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* Name */}
          <div className="text-lg flex flex-col">
            <label className="text-pink-400 text-sm mb-2">Nafn</label>
            <span className="bg-gray-800 p-5 rounded-lg shadow-lg">
              {profile.name}
            </span>
          </div>

          {/* Email */}
          <div className="text-lg flex flex-col">
            <label className="text-purple-400 text-sm mb-2">Netfang</label>
            <span className="bg-gray-800 p-5 rounded-lg shadow-lg">
              {profile.email}
            </span>
          </div>

          {/* SSN */}
          <div className="text-lg flex flex-col">
            <label className="text-blue-400 text-sm mb-2">Kennitala</label>
            <span className="bg-gray-800 p-5 rounded-lg shadow-lg">
              {profile.ssn}
            </span>
          </div>

          {/* Avatar */}
          <div className="flex flex-col items-center justify-center">
            <label className="text-white text-sm mb-4"></label>
            <img
              src={profile.avatar || '/default-avatar.png'}
              alt="Profile Avatar"
              className="w-40 h-40 md:w-48 md:h-48 rounded-full border-4 border-purple-500 shadow-xl transition-shadow duration-500 ease-in-out hover:shadow-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
