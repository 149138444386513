import React from 'react';
import { Doctor } from '../types/types'; // Adjust the path based on your folder structure

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  doctor: Doctor | null;
}

const DoctorProfileModal: React.FC<ModalProps> = ({ isOpen, onClose, doctor }) => {
  if (!isOpen || !doctor) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-60 transition-opacity duration-300 ease-in-out">
      <div className="w-full max-w-lg p-6 bg-white rounded-xl shadow-2xl transform transition-transform duration-300 ease-out scale-100 hover:scale-105">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-3xl font-bold text-gray-800">{doctor.fullname}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 transition duration-200 ease-in-out focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="text-gray-600">
          <p className="mb-2"><span className="font-semibold">Email:</span> {doctor.email}</p>
          <p className="mb-4"><span className="font-semibold">Role:</span> {doctor.role}</p>
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-5 py-2 text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition duration-300 ease-in-out"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DoctorProfileModal;
