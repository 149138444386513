import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ClipLoader } from 'react-spinners';
import { apiDoctorsList } from '../api/doctor';
import DoctorProfileModal from '../components/DoctorProfileModal'; // Adjust path based on your folder structure
import { Doctor } from '../types/types'; // Adjust the path based on your folder structure
import { useNavigate } from 'react-router-dom';

// Replace with your actual logo path
const logoSrc = '/testlogo.png';

const DoctorList: React.FC = () => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const context = useContext(AuthContext);
  const navigate = useNavigate(); // Add navigate for floating action button

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await apiDoctorsList();
        setDoctors(res.data.doctors);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        alert('Error loading doctors');
      }
    };

    if (auth?.isAdmin) {
      fetchData();
    }
  }, [auth]);

  const openModal = (doctor: Doctor) => {
    setSelectedDoctor(doctor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedDoctor(null);
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 p-6 flex flex-col">
      {/* Header with logo, enhanced title, and logout */}
      <div className="grid grid-cols-3 items-center max-w-5xl mx-auto p-4 w-full">
        {/* Logo on the far left */}
        <div className="justify-self-start">
          <img src={logoSrc} alt="Logo" className="h-14 w-14 rounded-full shadow-md" />
        </div>

        {/* Title with animation */}
        <div className="justify-self-center">
          <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-purple-500 tracking-wide animate-pulse">
            Læknar
          </h2>
        </div>

        {/* Logout Button on the far right */}
        <div className="justify-self-end">
          <button
            onClick={context?.logout ?? (() => { })}
            className="bg-red-500 text-white p-3 rounded-full shadow-md hover:bg-red-600 transition-all flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
            </svg>
          </button>
        </div>
      </div>

      {/* Main Content (Doctor List) */}
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-8 mt-6 transform transition-transform hover:scale-105">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader size={60} color={"#3b82f6"} />
          </div>
        ) : doctors.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {doctors.map((doctor, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl shadow-md p-6 flex flex-col justify-between transform transition-transform hover:scale-110 hover:shadow-xl"
              >
                <div>
                  <h3 className="text-2xl font-bold text-gray-900">{doctor.fullname}</h3>
                  <p className="text-gray-500">{doctor.email}</p>
                  <p className="text-indigo-600 font-semibold">{doctor.role}</p>
                </div>
                <button
                  onClick={() => openModal(doctor)} // Open modal when button is clicked
                  className="mt-4 bg-indigo-100 text-indigo-600 px-4 py-2 rounded-full hover:bg-indigo-200 transition-all text-base"
                >
                  Skoða
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No doctors found.</p>
        )}
      </div>

      {/* Modal */}
      <DoctorProfileModal
        isOpen={isModalOpen}
        onClose={closeModal}
        doctor={selectedDoctor}
      />

      {/* Larger Floating Action Button with Tooltip */}
      <div className="fixed bottom-6 right-6 flex items-center space-x-2 group">
        <button
          onClick={() => navigate('/create-doctor')}
          className="bg-gradient-to-r from-pink-500 to-purple-500 text-white rounded-full h-24 w-24 flex items-center justify-center shadow-xl hover:from-pink-600 hover:to-purple-600 transition-all transform hover:scale-110 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-12 h-12">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
        </button>
        <span className="opacity-0 group-hover:opacity-100 bg-white text-gray-800 text-sm font-semibold py-1 px-3 rounded-lg shadow-md transition-opacity duration-300">
          Skapa lækni
        </span>
      </div>
    </div>
  );
};

export default DoctorList;
