import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountDropdown from '../components/AccountDropdown';

const VaccinationsView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col items-center relative">
      {/* Fixed Account Dropdown and Back to Chat Arrow */}
      <div className="absolute top-6 right-6 z-50 flex items-center space-x-4">
        {/* Back to Chat Arrow */}
        <button
          onClick={() => navigate('/chat')}
          className="p-2 rounded-full bg-gradient-to-r from-red-400 to-orange-500 hover:bg-gradient-to-r hover:from-red-500 hover:to-orange-600 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        {/* Account Dropdown */}
        <AccountDropdown onLogout={() => console.log('Logged out')} />
      </div>

      {/* Full-Window Vaccinations Section */}
      <div className="w-full max-w-5xl p-12 flex flex-col justify-center items-center space-y-12">
        <h2 className="text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-red-400 via-orange-500 to-yellow-500 text-center leading-tight">
          Þínar bólusetningar
        </h2>

        {/* Example content for vaccinations */}
      </div>
    </div>
  );
};

export default VaccinationsView;
