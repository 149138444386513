import React from 'react';

interface ChatMessageProps {
  role: string;
  content: string | JSX.Element;
  profileImage: string;  // Add profileImage prop
}

const ChatMessage: React.FC<ChatMessageProps> = ({ role, content, profileImage }) => {
  const isUser = role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      {/* Only show profile image for the sender (Asa) */}
      {!isUser && (
        <img
          src={profileImage}
          alt="Asa profile"
          className="w-10 h-10 rounded-full mr-3"  // Circular image with margin on the right
        />
      )}
      <div
        className={`max-w-xs px-4 py-2 rounded-lg ${
          isUser 
            ? 'bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white' 
            : 'bg-blue-100 text-gray-900'
        } shadow-lg`}
      >
        {content}
      </div>
    </div>
  );
};

export default ChatMessage;
