import React from 'react';

interface SupportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SupportModal: React.FC<SupportModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
      <div className="bg-gradient-to-br from-gray-900 to-gray-700 text-white rounded-3xl shadow-2xl p-10 max-w-lg w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-300 hover:text-gray-100 focus:outline-none text-2xl"
        >
          ✕
        </button>
        <div className="flex items-center mb-6">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mr-4 text-teal-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5v1.125a3.375 3.375 0 11-6.75 0V10.5M5.25 10.5a7.5 7.5 0 1115 0v1.125a6 6 0 01-12 0V10.5" />
          </svg>
          <h2 className="text-4xl font-extrabold text-teal-300">Aðstoð</h2>
        </div>
        <p className="text-lg text-gray-300 mb-8">
          Vinsamlegast hafðu samband við okkur ef þú þarft aðstoð. Við erum hér til að hjálpa þér!
          <br />
          <span className="font-semibold text-teal-400">Símanúmer: <a href="tel:+3548991065" className="underline hover:text-teal-500">+354 899 1065</a></span>
        </p>
        <button
          onClick={onClose}
          className="w-full mt-4 bg-gradient-to-r from-teal-500 to-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:from-teal-600 hover:to-blue-700 transition-all focus:outline-none focus:ring-4 focus:ring-teal-400"
        >
          Loka
        </button>
      </div>
    </div>
  );
};

export default SupportModal;
