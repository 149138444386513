import React, { useContext, useState, useEffect } from 'react';
import { AuthContext, AuthContextType } from '../contexts/AuthContext';
import { apiGetAsaChatsByUrgency, apiMarkChatAsReadByDoctor, AsaChat, AsaChatsFilter } from '../api/asaChat';
import Header from '../components/Header';

const UrgentChatsList: React.FC = () => {
  const { user } = useContext(AuthContext) as AuthContextType;
  const [chats, setChats] = useState<AsaChat[]>([]);
  const [filter, setFilter] = useState<AsaChatsFilter>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [expandedChats, setExpandedChats] = useState<{ [key: string]: boolean }>({});
  const [sortOrder, setSortOrder] = useState<'newest' | 'oldest' | ''>('newest');

  const fetchChats = async () => {
    setLoading(true);
    const buttonIcon = document.getElementById('fetchIcon');
    if (buttonIcon) {
      buttonIcon.classList.add('animate-spin', 'duration-500');
    }
    try {
      console.log("Sæki spjall með síu:", filter);
      const response = await apiGetAsaChatsByUrgency(filter);
      console.log("API svörun:", response.data);

      let fetchedChats = response.data.chats;

      if (sortOrder === 'newest') {
        fetchedChats = fetchedChats.sort((a, b) => b.createdAt - a.createdAt);
      } else if (sortOrder === 'oldest') {
        fetchedChats = fetchedChats.sort((a, b) => a.createdAt - b.createdAt);
      }

      setChats(fetchedChats);
    } catch (err) {
      console.error("Villa við að sækja spjall:", err);
      setError('Tókst ekki að sækja spjall');
    } finally {
      setLoading(false);
      const buttonIcon = document.getElementById('fetchIcon');
      if (buttonIcon) {
        buttonIcon.classList.remove('animate-spin');
      }
    }
  };

  useEffect(() => {
    fetchChats();
  }, [filter, sortOrder]);

  const handleMarkAsRead = async (chatId: string) => {
    try {
      const result = await apiMarkChatAsReadByDoctor(chatId);
      if (result.data.success) {
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatId ? { ...chat, readByDoctor: true } : chat
          )
        );
      }
    } catch (err) {
      console.error('Tókst ekki að merkja spjall sem lesið:', err);
    }
  };

  const getUrgencyTagColor = (urgency: number) => {
    switch (urgency) {
      case 5:
        return 'bg-red-600 text-white';
      case 4:
        return 'bg-red-400 text-white';
      case 3:
        return 'bg-yellow-400 text-white';
      case 2:
        return 'bg-green-300 text-gray-800';
      case 1:
        return 'bg-green-200 text-gray-800';
      default:
        return 'bg-gray-300 text-gray-800';
    }
  };

  const toggleExpanded = (chatId: string) => {
    setExpandedChats((prevState) => ({
      ...prevState,
      [chatId]: !prevState[chatId],
    }));
  };

  const truncateText = (text: string, length: number) => {
    return text.length > length ? text.slice(0, length) + '...' : text;
  };

  const handleFilterChange = (key: keyof AsaChatsFilter, value: any) => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        [key]: value === '' ? undefined : value,
      };
      console.log("Uppfærð sía:", newFilter);
      return newFilter;
    });
  };

  const handleSortChange = (value: 'newest' | 'oldest' | '') => {
    setSortOrder(value);
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 text-white">
      <Header />
      <div className="w-full max-w-6xl p-8">
        {/* Filter Controls */}
        <div className="bg-gradient-to-r from-blue-800 to-purple-800 p-10 rounded-xl shadow-2xl text-gray-100 mb-10">
          <h2 className="text-4xl font-bold mb-8 text-center flex items-center justify-center">
            Spjall síur
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
            {/* Urgency Filter */}
            <div className="flex flex-col">
              <label htmlFor="urgency" className="font-semibold mb-3">Alvarleiki</label>
              <select
                id="urgency"
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : undefined;
                  handleFilterChange('urgency_from', value);
                  handleFilterChange('urgency_to', value);
                }}
                className="p-4 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-4 focus:ring-blue-500 shadow-lg"
                defaultValue="newest"
              >
                <option value="">Öll stig</option>
                <option value="5">5 - Hæsta</option>
                <option value="4">4</option>
                <option value="3">3</option>
                <option value="2">2</option>
                <option value="1">1 - Lægsta</option>
              </select>
            </div>

            {/* Read Status Filter */}
            <div className="flex flex-col">
              <label htmlFor="readStatus" className="font-semibold mb-3">Lestrarstaða</label>
              <select
                id="readStatus"
                onChange={(e) => handleFilterChange('read', e.target.value ? e.target.value === 'true' : undefined)}
                className="p-4 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-4 focus:ring-blue-500 shadow-lg"
                defaultValue=""
              >
                <option value="">Öll spjall</option>
                <option value="true">Lesið</option>
                <option value="false">Ólesið</option>
              </select>
            </div>

            {/* Sort By Time */}
            <div className="flex flex-col">
              <label htmlFor="sortOrder" className="font-semibold mb-3">Raða eftir tíma</label>
              <select
                id="sortOrder"
                onChange={(e) => handleSortChange(e.target.value as 'newest' | 'oldest' | '')}
                className="p-4 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-4 focus:ring-blue-500 shadow-lg"
                defaultValue=""
              >
                <option value="">Sjálfgefið</option>
                <option value="newest">Nýjasta fyrst</option>
                <option value="oldest">Elsta fyrst</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-12">
          <button
            onClick={fetchChats}
            className="relative bg-gradient-to-r from-blue-500 to-purple-600 text-white px-8 py-4 rounded-full shadow-2xl hover:from-purple-600 hover:to-blue-500 transition-all duration-700 ease-in-out focus:outline-none focus:ring-4 focus:ring-blue-300 font-semibold text-xl flex items-center hover:animate-pulse"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" id="fetchIcon" className="w-6 h-6 mr-4 transition-transform">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
            </svg>
            Sækja ný spjöll
          </button>
        </div>

        {/* Chat List */}
        <div className="mt-8 space-y-8">
          
          {error && <div className="text-center text-red-400 text-2xl font-semibold">{error}</div>}

          {chats.length > 0 ? (
            chats.map((chat) => (
              <div
                key={chat.id}
                className={`p-8 rounded-xl shadow-xl backdrop-filter backdrop-blur-lg bg-opacity-30 bg-gray-800 transition-transform transform hover:scale-105 hover:bg-opacity-40 ${
                  chat.readByDoctor ? 'opacity-70' : 'opacity-100'
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className={`w-10 h-10 rounded-full flex items-center justify-center ${getUrgencyTagColor(chat.urgency)}`}>
                    <span className="font-bold text-xl">{chat.urgency}</span>
                  </div>

                  <button
                    onClick={() => handleMarkAsRead(chat.id)}
                    className={`${
                      chat.readByDoctor ? 'hidden' : 'visible'
                    } bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 transition-all focus:outline-none focus:ring-4 focus:ring-green-400`}
                    aria-label={`Merkja spjall frá ${chat.user.fullname} sem lesið`}
                  >
                    Merkja sem lesið
                  </button>
                </div>

                <div className="mt-6">
                  <p className="font-semibold text-2xl text-white mb-2">{chat.user.fullname}</p>
                  <p className="text-md text-gray-300 mb-1">{chat.user.email}</p>
                  <p className="text-md text-gray-300">Kennitala: {chat.user.ssn}</p>
                  <p className="text-sm text-gray-400 mt-4">
                    Búið til: {getTimeString(chat.createdAt)}
                  </p>
                  <p className="text-sm text-gray-400">Uppfært: {getTimeString(chat.updatedAt)}</p>
                </div>

                <div className="mt-6">
                  <p className="text-lg text-gray-200">
                    Greining:{' '}
                    {expandedChats[chat.id]
                      ? chat.diagnosis
                      : truncateText(chat.diagnosis, 60)}
                  </p>
                  <button
                    onClick={() => toggleExpanded(chat.id)}
                    className="text-blue-400 hover:underline text-md mt-4 focus:outline-none focus:ring-4 focus:ring-blue-400"
                  >
                    {expandedChats[chat.id] ? 'Sýna minna' : 'Sýna meira'}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-400 text-2xl">Engin spjall fundust.</p>
          )}
        </div>
      </div>
    </div>
  );
};

const getTimeString = (timestampSeconds: number) => {
  const date = new Date(timestampSeconds * 1000);
  const dateDay = new Date(date);
  const today = new Date();

  dateDay.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (dateDay.getTime() === today.getTime()) {
    return formatTime(date) + ' í dag';
  } else if (dateDay.getTime() === yesterday.getTime()) {
    return formatTime(date) + ' í gær';
  } else {
    return date.toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  }
};

function formatTime(date: Date) {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export default UrgentChatsList;
