import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountDropdown from '../components/AccountDropdown';

const PrescriptionsView: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToChat = () => {
    navigate('/chat'); // Navigates back to the ChatView
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white flex flex-col items-center relative">
      {/* Fixed Account Dropdown and Back to Chat Arrow */}
      <div className="absolute top-6 right-6 z-50 flex items-center space-x-4">
        {/* Back to Chat Arrow */}
        <button
          onClick={handleBackToChat}
          className="p-2 rounded-full bg-gradient-to-r from-pink-400 to-purple-500 hover:bg-gradient-to-r hover:from-pink-500 hover:to-purple-600 shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-110"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        {/* Account Dropdown */}
        <AccountDropdown onLogout={() => console.log('Logged out')} />
      </div>

      {/* Full-Window Prescriptions Section */}
      <div className="w-full max-w-5xl p-12 flex flex-col justify-center items-center space-y-12">
        <h2 className="text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-pink-400 via-purple-500 to-blue-500 text-center leading-tight">
          Þínir lyfseðlar
        </h2>

        {/* Example content for prescriptions */}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="text-lg flex flex-col">
            <label className="text-pink-400 text-sm mb-2">Prescription 1</label>
            <span className="bg-gray-800 p-5 rounded-lg shadow-lg">
              This is a placeholder for Prescription 1 details.
            </span>
          </div>
          <div className="text-lg flex flex-col">
            <label className="text-purple-400 text-sm mb-2">Prescription 2</label>
            <span className="bg-gray-800 p-5 rounded-lg shadow-lg">
              This is a placeholder for Prescription 2 details.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionsView;
